@tailwind base;
@tailwind components;
@tailwind utilities;




body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}


/* Add these styles to your component's stylesheet */
.limit-info {
  margin-top: 8px;
  font-size: 14px;
  color: #666;
}

.limit-value {
  font-weight: bold;
  color: #333;
}

