/* Add this CSS to your project's stylesheet */
.quicksight-iframe-container {
    width: 100%; /* Make the container responsive */
    max-width: 100%; /* Limit the container's width if needed */
    padding: 20px; /* Add spacing around the iframe */
    border: 1px solid #ccc; /* Add a border to the container */
    border-radius: 8px; /* Add rounded corners to the container */
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); /* Add a subtle shadow to the container */
  }
  
  .quicksight-iframe {
    width: 100%; /* Make the iframe fill its container */
    height: 720px; /* Set the initial height */
    border: none; /* Remove the iframe border */
  }
  